import * as React from "react";
import Layout from "@src/components/templates/layout";
import Seo from "@src/components/seo";
import Marketing from "@src/components/pages/marketing/index";

const MarketingPage = () => {
    return(
        <Layout>
            <Seo title="運用事業部" />
            <Marketing />
        </Layout>
    )
}

export default MarketingPage